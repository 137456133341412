<template>
  <div class="user">
    <div class="user-info">
      <div v-if="store.state.token">
        <div class="user-img">
          <img src="../assets/img/usertx.png" alt="">
        </div>
        <div class="shiming flex">
          <img src="../assets/img/userv.png" alt="">
          <p>{{ store.state.loginInfo.isOrgAuth === '0' ? '未认证' : '已认证' }}</p>        
        </div>
        <router-link to="/auth" v-show="store.state.loginInfo.isOrgAuth === '0'" class="go_shiming flex">
          <img src="../assets/img/userv2.png" alt="">
          <p>马上认证</p>     
        </router-link>
        <div v-show="store.state.loginInfo.isOrgAuth === '1'" class="go_shiming flex" @click="changePop(1)">
          <img src="../assets/img/userv2.png" alt="">
          <p>认证情况</p>     
        </div>
        <div class="user-phone">
          <p>{{store.state.loginInfo.enterName}}</p>
          <p style="padding-top: 2vw">158*****888</p>
        </div>
        <img class="xximg" src="../assets/img/userxx.png" alt="">
        <div class="gxm flex" @click="checkLogin('gxm')">
          <img src="../assets/img/userewm.png" alt="">
          <p>桂信码</p>        
        </div>
      </div>
      <div v-else>
        <div class="user-img">
          <img src="../assets/img/usertx.png" alt="">
        </div>
        <router-link to="/login" class="user-phone">
          <p>点击登录</p>
          <p style="font-size: 4vw;padding-top: 1vw">登录获取更多服务</p>
        </router-link>
        <img class="xximg" src="../assets/img/userxx.png" alt="">
        <div class="gxm flex" @click="checkLogin('gxm')">
          <img src="../assets/img/userewm.png" alt="">
          <p>桂信码</p>        
        </div>
      </div>

    </div>
    <div class="user-set">
      <div @click="checkLogin('companyInfo')" class="rows flex">
        <img src="../assets/img/user_icon1.png" alt="">
        <p>企业基本信息</p>
        <van-icon name="arrow" color="#ccc" />
      </div>
      <div @click="checkLogin('creditAuth')" class="rows flex">
        <img src="../assets/img/user_icon2.png" alt="">
        <p>信用授权管理</p>
        <van-icon name="arrow" color="#ccc" />
      </div>
      <router-link to="/loanProgress" class="rows flex">
        <img src="../assets/img/user_icon3.png" alt="">
        <p>贷款进度</p>
        <van-icon name="arrow" color="#ccc" />
      </router-link>
      <router-link to="/openAccount"  class="rows flex">
        <img src="../assets/img/user_icon4.png" alt="">
        <p>开户预约</p>
        <van-icon name="arrow" color="#ccc" />
      </router-link>
      <div @click="checkLogin('collect')" class="rows flex">
        <img src="../assets/img/user_icon5.png" alt="">
        <p>产品收藏</p>
        <van-icon name="arrow" color="#ccc" />
      </div>
      <div @click="checkLogin('chat')" class="rows flex">
        <img src="../assets/img/duihua.png" alt="">
        <p>银企对话</p>
        <van-icon name="arrow" color="#ccc" />
      </div>
      <router-link to="/systemIntroduction" class="rows flex">
        <img src="../assets/img/user_icon6.png" alt="">
        <p>系统介绍</p>
        <van-icon name="arrow" color="#ccc" />
      </router-link>
      <router-link to="/contactUs" class="rows flex">
        <img src="../assets/img/user_icon7.png" alt="">
        <p>联系我们</p>
        <van-icon name="arrow" color="#ccc" />
      </router-link>      
      <router-link to="/faq" class="rows flex">
        <img src="../assets/img/user_icon8.png" alt="">
        <p>系统帮助</p>
        <van-icon name="arrow" color="#ccc" />
      </router-link>
      <div @click="checkLogin('passwordChange')" class="rows flex">
        <img src="../assets/img/user_icon9.png" alt="">
        <p>密码修改</p>
        <van-icon name="arrow" color="#ccc" />
      </div>
      <div class="rows flex" @click="logOff()">
        <img src="../assets/img/user_icon10.png" alt="">
        <p>退出登录</p>
        <van-icon name="arrow" color="#ccc" />
      </div>

    </div>
    <!-- 认证情况 -->
    <van-popup round v-model:show="showAuth">
        <div class="pop">
            <div class="pop-header">认证情况</div>
            <div class="pop-confirm">
                <div class="content">
                    <p>认证机构：</p>
                    <p>{{authInfo.BANK_NAME}}</p>
                </div>
                <div class="content">
                    <p>认证时间：</p>
                    <p>{{authInfo.AUTH_TIME}}</p>
                </div>
                <div class="content">
                    <p>认证方式：</p>
                    <p>{{authInfo.AUTH_TYPE}}</p>
                </div>
            </div>
            <div class="pop-footer">
                <div class="next" @click="changePop(0)">确认</div>
                <router-link to="/auth" class="next">重新认证</router-link>
            </div>
        </div>
    </van-popup>
    <!-- <Sizer /> -->
    <div style="height: 15vw; width:100%"></div>
    <TabBar nav="about"/>
  </div>
</template>
<script>
import TabBar from '../components/TabBar.vue';
import {useRouter} from 'vue-router';
import { useStore } from 'vuex';
import { ref,onMounted } from 'vue';
import { Toast,Dialog } from 'vant';
import {apiGxiAuth} from '../api/axios';
export default {
  setup() {
    let router = useRouter();
    let store = useStore();
     const showAuth = ref(false);
     const changePop = (i) =>{
        if(i){
            showAuth.value = true;
        }else {
            showAuth.value = false;
        }
        
    };
    //退出登录
    const logOff = () =>{
      if(store.state.token) {
        store.dispatch('setToken',{token: '',isComUser: ''});
        localStorage.removeItem("gxrToken");
        Toast('退出成功！');
      }else {
        Toast('您还没有登录！');
      }
        
    };
// 检查登录
      const checkLogin = (routerName) => {
          if(store.state.token) {
            router.push({name: routerName})
          }else {
            Dialog.confirm({
              title: '提示！',
              confirmButtonText: '去登录',
              message:
                '您还没有登录！点击下方按钮去登录。',
              })
              .then(() => {
                router.push({path: '/login'});
              })
              .catch(() => {
                console.log('取消登录!')
              });
          }
      };
      const authInfo = ref({});
    onMounted(() => {
      if(store.state.token) {
        let time = Date.parse(new Date()).toString();
        //查询企业认证记录
        apiGxiAuth({reqType: 'Q0201',timestap: time,qryType: '0'}).then(res => {
              console.log(res,'返回的数据')                   
              if(res.code == '1'){
                  authInfo.value = res.info.info;
                }
                else {
                  Toast(res.msg);
                }
        });
      }
      
    });
    return{
        store,
        showAuth,
        changePop,
        logOff,
        checkLogin,
        authInfo
    }
  },
  components: {
    TabBar
  }
}
</script>
<style lang="less" scoped>
.user {
  .user-info {
    width: 100%;
    height: 44vw;
    background-image: url('../assets/img/userbg.png');
    background-size: cover;
    position: relative;
    .user-img {
      width: 17.4vw;
      height: 17.4vw;
      background-color: #fff;
      border-radius: 100%;
      padding: 1vw;
      overflow: hidden;
      position: absolute;
      left: 5vw;
      top: 15vw;
      img {
        width: 100%;
      }
    }
    .shiming {
      width: 18.4vw;
      background: linear-gradient(to right, #29d8b5, #5ac6ea);
      height: 6vw;
      border-radius: 4vw;
      justify-content: center;
      position: absolute;
      left: 5vw;
      bottom: 7vw;
      img {
        height: 4vw;
      }
      p {
        font-size: 3.3vw;
        color: #fff;
        padding-left: 1vw;
        line-height: 3.3vw;
      }
    }
    .go_shiming {
      width: 23vw;
      background: linear-gradient(to right, #fdb012, #ff9900);
      height: 6vw;
      border-radius: 4vw;
      justify-content: center;
      position: absolute;
      left: 27vw;
      bottom: 7vw;
      img {
        height: 3.5vw;
      }
      p {
        font-size: 3.3vw;
        color: #fff;
        padding-left: 1vw;
        line-height: 3.3vw;
      }
    }
    .user-phone {
      height: 17.4vw;
      color: #fff;
      position: absolute;
      top: 16vw;
      left: 26vw;
    }

    .xximg {
      width: 7vw;
      position: absolute;
      right: 5vw;
      top: 8vw;
    }
    .gxm {
      width: 28vw;
      background: rgba(0,0,0,.2);
      height: 10.5vw;
      border-radius: 6vw;
      justify-content: center;
      position: absolute;
      right: 5vw;
      bottom: 14vw;
      img {
        height: 5vw;
      }
      p {
        font-size: 4vw;
        color: #fff;
        padding-left: 2vw;
        line-height: 4.5vw;
      }
    }
  }
  .user-set {
    margin: 4vw;
    background-color: #fff;
    border-radius: 1.5vw;
    padding: 4vw 4vw 0;
    .rows {
      padding-bottom: 6vw;
      img {
        width: 4.5vw;
        height: auto;
        margin-right: 2vw;
      }
      p {
        flex: 1;
        font-size: 4vw;
        color: #333;
      }
    }
  }
  .pop {
        padding: 4vw;
        width: 70vw;        
        .pop-header {
            font-size: 5vw;
            font-weight: 600;
            text-align: center;
            margin-bottom: 4vw;
        }
        .pop-confirm {
            .content {
                padding: 3vw 2vw;
                border-bottom: 1px solid #f5f5f5;
                display: flex;
                align-items: baseline;
                p{
                    font-size: 4vw;
                }
                p:first-child {
                    width: 34%;
                }
                p:last-child{
                    width: 66%;
                }
            }
            
             
        }

        .pop-footer {
            text-align: center;
            padding-top: 4vw;
            .next {
                display: inline-block;
                background: linear-gradient(to right, #ff9900, #ff6700);
                color: #fff;
                font-size: 4vw;
                padding: 1.5vw 0;
                border-radius: 5vw;
                margin: 0 2vw;
                width: 22vw;
            }
        }
    }
}
</style>
